import React, { useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import Header from '../components/Header';
import styled from 'styled-components';
import Footer from '../components/Footer';
import Sideflaps from '../components/Sideflaps';
import BusinessCard from '../components/BusinessCard';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  :root, html {
    height: 100%;
  }

  body {
		--color-primary: #066aac;
    --color-secondary: #00a096;
		--color-black: #000000;
    --color-blue: #aed9ea;
    --color-yellow: #f0e0a2;
    --color-orange: #d07967;
    --color-red: #c06c84;
    --color-green: #adddcf;
    --color-lightBlue: #e0f3ff;
    --color-lightGreen: #e4fff7;
    --color-lightOrange: #ffefeb;
    --color-lightRed: #ffe5ec;
    --color-lightYellow: #fffbea;
		--color-text: #000000;
		--border-small: 1px solid #dcdcdc;
		--border-medium: 2px solid #dcdcdc;

    margin: 0;
    font-family: 'Livvic', sans-serif;
    height: 100%;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Livvic', serif;
    margin: 0;
  }

  h1 {
    font-size: 2rem;
    @media(min-width: 576px) {
      font-size: 4rem
    }
  }

  h2 {
    @media(min-width: 576px) {
      font-size: 2.5rem;
    }
  }

  h3 {
    @media(min-width: 576px) {
      font-size: 2rem;
    }
  }

  p {
    line-height: 1.5;
    margin-top: 0;
  }

  nav {
    ul, li {
      font-family: 'Livvic', serif;
    }

    li {
      margin-bottom: 0;
    }
  }

  li {
    margin-bottom: 1.5rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    > ul {
      margin-top: 1rem;
    }
  }

  ul {
    margin: 0;
  }

  .gatsby-image-wrapper:not(.border-0) {
    border-radius: 32px;
    img {
      border-radius: 32px;
    }
  }

  .gatsby-image-wrapper.bg-white {
    [data-placeholder-image] {
      background-color: white !important;
    }
  }

  .gatsby-image-wrapper {
    max-width: 100%;
    position: relative;
    z-index: 0;

    @media(min-width: 576px) {
        max-width: 800px;
      }
  }

  #___gatsby {
    height: 100%;
  }

  #gatsby-focus-wrapper {
    height: 100%;
  }
`;

const StyledContainer = styled.div`
  margin: 0 auto;
  height: 100%;

  @media (max-width: 575px) {
    overflow: ${({ isNavigationOpen, isBusinessCardOpen }) =>
      isNavigationOpen || isBusinessCardOpen ? 'auto' : 'revert'};
  }
`;

const StyledMainContainer = styled.main`
  padding-top: 4rem;
  min-height: calc(100% - 4rem);
`;

export default function Layout({ children }) {
  const [isBusinessCardOpen, setBusinessCardOpen] = useState(false);
  const [isNavigationOpen, setNavigationOpen] = useState(false);

  return (
    <>
      <GlobalStyle />
      <StyledContainer
        isBusinessCardOpen={isBusinessCardOpen}
        isNavigationOpen={isNavigationOpen}
      >
        <Header
          isNavigationOpen={isNavigationOpen}
          setNavigationOpen={setNavigationOpen}
        ></Header>
        <StyledMainContainer>{children}</StyledMainContainer>
        <Footer></Footer>
        <Sideflaps
          showCloseIcon={isBusinessCardOpen}
          clickHandler={() => setBusinessCardOpen(!isBusinessCardOpen)}
        ></Sideflaps>
      </StyledContainer>
      <BusinessCard
        isOpen={isBusinessCardOpen}
        closeHandler={() => setBusinessCardOpen(false)}
      ></BusinessCard>
    </>
  );
}
