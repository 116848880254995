import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.footer`
  width: 100%;
  background-color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;

  ul {
    display: flex;
    list-style-type: none;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    gap: 2rem;
    align-items: center;
    height: 4rem;
  }

  li {
    color: white;
    text-decoration: none;
    margin-bottom: 0;
  }

  a {
    color: white;
    text-decoration: none;

    &:hover {
      font-weight: 900;
    }
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`;

function Footer() {
  return (
    <StyledFooter>
      <StyledContainer>
        <ul>
          <li>
            <Link to='/datenschutz'>Datenschutz</Link>
          </li>
          <li>
            <Link to='/impressum'>Impressum</Link>
          </li>
        </ul>
      </StyledContainer>
    </StyledFooter>
  );
}

export default Footer;
