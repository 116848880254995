const navigationItems = [
  {
    link: '/',
    title: 'Home',
  },
  {
    link: '/autismustherapie',
    title: 'Therapie',
    children: [
      {
        link: '/autismustherapie',
        title: 'Therapie',
      },
      {
        link: '/kinder',
        title: 'Kinder (2 - 12)',
      },
      {
        link: '/jugendliche',
        title: 'Jugendliche (12 - 18)',
      },
      {
        link: '/erwachsene',
        title: '(Junge) Erwachsene',
      },
      {
        link: '/kostenuebernahme',
        title: 'Kostenübernahme',
      },
      {
        link: '/methoden',
        title: 'Methoden',
      },
    ],
  },
  {
    link: '/fortbildung',
    title: 'Fortbildung',
  },
  {
    link: '/team',
    title: 'Team',
    children: [
      {
        link: '/team',
        title: 'Team',
      },
      {
        link: '/leitung',
        title: 'Gründerin & Leitung',
      },
      {
        link: '/jobs',
        title: 'Jobs',
      },
    ],
  },
  {
    link: '/autismus',
    title: 'Autismus',
  },
  {
    link: '/therapiematerial',
    title: 'Material',
  },
];

export default navigationItems;
