import React from 'react';
import styled from 'styled-components';

const StyledBackdrop = styled.div`
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 50%;
  height: 100vh;
  z-index: 9;
  background-color: black;

  @media (min-width: 576px) {
    display: block;
  }
`;

function Backdrop({ clickHandler }) {
  return <StyledBackdrop onClick={() => clickHandler()}></StyledBackdrop>;
}

export default Backdrop;
