import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import MobileNavigation from './MobileNavigation';
import DesktopNavigation from './DesktopNavigation';
import navigationItems from './navigation-items';

const StyledHeader = styled.header`
  border-bottom: var(--border-small);
  position: fixed;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-inline: 1rem;
  z-index: 9999;
  width: 100%;
  background: white;
  height: 4rem;
  top: 0;

  h1 {
    margin: 0;
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  display: block;
  color: var(--color-primary);
  z-index: 9999;
`;

function Header({ isNavigationOpen, setNavigationOpen }) {
  const breakpoints = useBreakpoint();

  return (
    <>
      <StyledHeader>
        {breakpoints.sm ? (
          <StyledIcon
            aria-label='Navigation öffnen und schließen'
            icon={isNavigationOpen ? faTimes : faBars}
            size='2x'
            onClick={() => setNavigationOpen(!isNavigationOpen)}
          />
        ) : (
          <>
            {breakpoints.sm !== undefined && (
              <DesktopNavigation items={navigationItems}></DesktopNavigation>
            )}
          </>
        )}
      </StyledHeader>
      {breakpoints.sm && (
        <MobileNavigation
          isOpen={isNavigationOpen}
          setOpen={setNavigationOpen}
          items={navigationItems}
        ></MobileNavigation>
      )}
    </>
  );
}

export default Header;
