import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faTimes } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const StyledAside = styled.aside`
  position: fixed;
  z-index: 99;
  bottom: 0;
  right: 1rem;
  bottom: 1rem;

  button {
    background-color: white;
    border: 0;
    border-radius: 50%;
    color: var(--color-primary);
    padding: 0.75rem;
    cursor: pointer;
    box-shadow: 1px 5px 20px rgba(0, 0, 0, 0.5);
    width: 4rem;
    height: 4rem;
    outline: none;

    :focus-visible {
      border: 2px solid;
    }

    svg {
      position: relative;
    }
  }
`;

function Sideflaps({ clickHandler, showCloseIcon }) {
  return (
    <StyledAside>
      <button onClick={() => clickHandler()}>
        <FontAwesomeIcon
          icon={showCloseIcon ? faTimes : faPaperPlane}
          size='2x'
        />
      </button>
    </StyledAside>
  );
}

export default Sideflaps;
