import React, { useEffect } from 'react';
import {
  faTimes,
  faPhone,
  faEnvelope,
  faHome,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import Backdrop from './Backdrop';

const StyledIcon = styled(FontAwesomeIcon)`
  display: block;
  color: var(--color-primary);

  @media (min-width: 576px) {
    margin-right: 0.5rem;
  }
`;

const StyledBusinessCard = styled.div`
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  position: fixed;
  background: white;
  overflow: hidden;

  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};

  @media (min-width: 576px) {
    transition: all 300ms ease-in-out;
  }

  > svg {
    margin-left: auto;
    margin-top: 1rem;
    margin-right: 1rem;
    cursor: pointer;
    display: block;

    @media (min-width: 576px) {
      display: none;
      height: revert;
    }
  }

  @media (min-width: 576px) {
    top: 50%;
    left: 50%;
    width: 90%;
    height: fit-content;
    max-width: 850px;
    transform: translate(-50%, -50%);
    box-shadow: 1px 5px 20px rgba(0, 0, 0, 0.5);
    border-radius: 16px;
    padding-bottom: 1rem;
  }
`;

const StyledBusinessCardBody = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const StyledContactInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;

  a {
    text-decoration: none;
    color: black;

    &:hover {
      color: var(--color-primary);
    }
  }

  @media (min-width: 576px) {
    flex-direction: row;
  }
`;

const StyledAddress = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  a {
    align-items: flex-end;
    display: flex;

    &:hover {
      color: var(--color-primary);
    }
  }

  @media (min-width: 576px) {
    text-align: right;
  }
`;

const StyledContactInfoContainer = styled.div`
  margin-top: 1rem;
  gap: 1rem;
  display: grid;

  p {
    margin: 0;
  }

  @media (min-width: 576px) {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  @media (min-width: 769px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const StyledImageWrapper = styled.div`
  margin: 0 auto;

  @media (min-width: 576px) {
    align-self: flex-start;
  }
`;

const StyledAddressContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;

  @media (min-width: 576px) {
    flex-direction: row;
  }
`;

const StyledAddressParagraph = styled.p`
  a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    color: black;
    align-items: center;

    @media (min-width: 576px) {
      align-items: revert;
    }
  }
`;

function BusinessCard({ closeHandler, isOpen }) {
  const onEscapeClick = (event) => {
    if (event.keyCode === 27) {
      closeHandler();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onEscapeClick);

    return function cleanup() {
      document.removeEventListener('keydown', onEscapeClick);
    };
  }, []);

  return (
    <>
      {isOpen && <Backdrop clickHandler={() => closeHandler()}></Backdrop>}
      <StyledBusinessCard isOpen={isOpen}>
        <StyledIcon
          aria-label='Schließ Symbol'
          icon={faTimes}
          size='2x'
          onClick={() => closeHandler()}
        />
        <StyledBusinessCardBody>
          <StyledImageWrapper>
            <StaticImage src='../images/logo.png' alt='logo' />
          </StyledImageWrapper>
          <StyledContactInfoContainer>
            <StyledContactInfo>
              <a href='tel:+4915738930161'>
                <StyledIcon icon={faPhone} size='2x' />
              </a>
              <p>
                <a href='tel:020336960370'>
                  Tel: &nbsp;&nbsp;&nbsp;&nbsp; 0203 36960370
                </a>
                <br />
                <a href='tel:+4915738930161'>Mobil: 0157 38930161</a>
              </p>
            </StyledContactInfo>
            <StyledContactInfo>
              <a href='mailto:info@lernen-rueckenwind.de'>
                <StyledIcon icon={faEnvelope} size='2x' />
              </a>
              <p>
                <a href='mailto:info@lernen-rueckenwind.de'>
                  info@lernen-rueckenwind.de
                </a>
              </p>
            </StyledContactInfo>
            <StyledAddressContainer>
              <a
                href='https://goo.gl/maps/za9RiKQnHbA1kXsQ7'
                target='_blank'
                rel='noreferrer'
              >
                <StyledIcon icon={faHome} size='2x' />
              </a>
              <StyledAddress>
                <StyledAddressParagraph>
                  <a
                    href='https://goo.gl/maps/za9RiKQnHbA1kXsQ7'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <span>Tonhallenstraße 19</span>
                    <span>Friedrich-Wilhelm-Str. 80</span>
                    <span>47051 Duisburg</span>
                  </a>
                </StyledAddressParagraph>
              </StyledAddress>
            </StyledAddressContainer>
          </StyledContactInfoContainer>
        </StyledBusinessCardBody>
      </StyledBusinessCard>
    </>
  );
}

export default BusinessCard;
