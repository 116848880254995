import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import AnimateHeight from 'react-animate-height';
import Backdrop from './Backdrop';

const StyledNavigationContainer = styled.div`
  top: 4rem;
  bottom: 0;
  left: ${(props) => (props.open ? '0' : '-1000px')};
  width: 100%;
  background-color: white;
  z-index: 999;
  position: fixed;
  overflow: hidden;
  transition: all 200ms ease-in-out;

  @media (min-width: 768px) {
    width: 65%;
    border-right: var(--border-small);
  }
`;

const StyledNavigation = styled.nav`
  list-style-type: none;
  margin: 0;
  display: flex;
  padding: 0;
  background-color: white;
  height: 100%;
  overflow-y: auto;
`;

const StyledNavigationList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
`;

const StyledSubNavigationList = styled.ul`
  margin-top: 0.5rem;
  list-style-type: none;
  padding: 0;
  font-size: 0.75rem;

  a {
    font-size: 1.5rem;
    width: 100%;
    display: block;
    color: var(--color-primary);
  }
`;

const StyledListItem = styled.li`
  padding: 2em 1em;

  &:not(:last-of-type) {
    border-bottom: var(--border-small);
  }

  svg {
    margin: auto 0;
  }
`;

const StyledSubListItem = styled.li`
  &:not(:last-of-type) {
    border-bottom: var(--border-small);
  }
  padding: 1em;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
  font-size: 2rem;
  width: 100%;
  color: var(--color-primary);
`;

const StyledLinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledArrowIconContainer = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: flex-end;
`;

function MobileNavigation({ items, isOpen, setOpen }) {
  const [openNavigations, setOpenNavigations] = useState([]);

  const onArrowIconClick = (item) => {
    const isOpen = getNavigationOpen(item);
    const currentNavigationItem = {
      ...item,
      isOpen: !isOpen,
    };
    let currentOpenNavigations = openNavigations.slice();
    const currentItemIndex = currentOpenNavigations.findIndex(
      (navigationItem) => navigationItem.title === item.title,
    );
    currentOpenNavigations = closeAllNavigations(currentOpenNavigations);
    currentOpenNavigations[currentItemIndex] = currentNavigationItem;

    setOpenNavigations(currentOpenNavigations);
  };

  const initOpenNavigations = () => {
    const openNavigations = items.map((item) => ({
      title: item.title,
      isOpen: false,
    }));

    setOpenNavigations(openNavigations);
  };

  const closeAllNavigations = (navigations) => {
    return navigations.map((navigation) => ({
      ...navigation,
      isOpen: false,
    }));
  };

  const getNavigationOpen = (currentNavigation) => {
    return openNavigations.find(
      (navigation) => navigation.title === currentNavigation.title,
    )?.isOpen;
  };

  useEffect(() => {
    initOpenNavigations();
  }, []);

  useEffect(() => {
    if (!isOpen && openNavigations.length) {
      const currentOpenNavigations = closeAllNavigations(openNavigations);
      setOpenNavigations(currentOpenNavigations);
    }
  }, [isOpen]);

  return (
    <>
      {isOpen && <Backdrop clickHandler={() => setOpen(false)}></Backdrop>}
      <StyledNavigationContainer open={isOpen}>
        <StyledNavigation>
          <StyledNavigationList>
            {items.map((item) => (
              <StyledListItem key={item.link}>
                <StyledLinkContainer>
                  <StyledLink onClick={() => setOpen(false)} to={item.link}>
                    {item.title}
                  </StyledLink>
                  {!!item.children && (
                    <StyledArrowIconContainer
                      onClick={() => onArrowIconClick(item)}
                    >
                      <FontAwesomeIcon
                        icon={
                          getNavigationOpen(item) ? faChevronUp : faChevronDown
                        }
                        size='1x'
                      />
                    </StyledArrowIconContainer>
                  )}
                </StyledLinkContainer>
                {!!item.children && (
                  <AnimateHeight
                    duration={300}
                    height={getNavigationOpen(item) ? 'auto' : 0}
                  >
                    <StyledSubNavigationList>
                      {item.children?.map((child) => (
                        <StyledSubListItem key={child.link}>
                          <StyledLink
                            onClick={() => setOpen(false)}
                            to={child.link}
                          >
                            {child.title}
                          </StyledLink>
                        </StyledSubListItem>
                      ))}
                    </StyledSubNavigationList>
                  </AnimateHeight>
                )}
              </StyledListItem>
            ))}
          </StyledNavigationList>
        </StyledNavigation>
      </StyledNavigationContainer>
    </>
  );
}

export default MobileNavigation;
