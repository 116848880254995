import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';

const StyledNavigation = styled.nav`
  display: flex;
  list-style-type: none;
  margin: 0 auto;
  height: 100%;
`;

const StyledNavigationListItem = styled.li`
  font-size: 1rem;
  display: block;
  flex-direction: column;
  height: 100%;

  > a {
    &[aria-current]:not([aria-current='false']) {
      text-decoration: underline;
    }
  }

  a {
    color: var(--color-primary);
    text-decoration: none;
    height: 100%;
    padding: 1rem;
    outline: none;

    @media (min-width: 768px) {
      padding: 1rem 1.25rem;
    }
  }

  &:hover,
  &:focus-within {
    a {
      background-color: var(--color-primary);
      color: white;
    }

    ul {
      display: flex;
    }
  }

  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
`;

const StyledNavigationChildrenList = styled.ul`
  list-style-type: none;
  display: none;
  position: absolute;
  justify-content: center;
  margin: 0;
  padding: 0;
  left: 0;
  width: 100vw;
  background-color: var(--color-primary);

  li:hover,
  li:focus-within {
    a {
      background-color: var(--color-secondary);
    }
  }

  a {
    display: inline-flex;
    white-space: nowrap;
    padding: 1.25rem 0.75rem;
    font-size: 0.85rem;
    color: white;

    @media (min-width: 768px) {
      font-size: 1rem;
    }
  }
`;

const StyledLinkTitle = styled.span`
  display: inline;
  margin-right: 0.5rem;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;

  svg {
    height: 0.5rem;
    width: 0.5rem;
  }
`;

const StyledNavigationSubListItem = styled.li``;

function DesktopNavigation({ items }) {
  const onNavigationItemClick = (e) => {
    e.target.blur();
    e.target.parentElement.blur();
  };

  const onSubNavigationItemClick = (e) => {
    e.target.blur();
    e.target.parentElement.blur();
  };

  const onMouseDown = (e) => {
    e.target.click();
  };

  return (
    <StyledNavigation>
      {items.map((item) => (
        <StyledNavigationListItem key={item.link}>
          <StyledLink onClick={onNavigationItemClick} to={item.link}>
            <StyledLinkTitle>{item.title}</StyledLinkTitle>
            {!!item.children && (
              <FontAwesomeIcon icon={faChevronDown} size='1x' />
            )}
          </StyledLink>
          {!!item.children && (
            <StyledNavigationChildrenList>
              {item.children.map((child) => (
                <StyledNavigationSubListItem key={child.link}>
                  <Link
                    onClick={onSubNavigationItemClick}
                    onMouseDown={onMouseDown}
                    to={child.link}
                  >
                    <span>{child.title}</span>
                  </Link>
                </StyledNavigationSubListItem>
              ))}
            </StyledNavigationChildrenList>
          )}
        </StyledNavigationListItem>
      ))}
    </StyledNavigation>
  );
}

export default DesktopNavigation;
